/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  StyledButton,
} from "@components/StyledComponents";
import {
  ApprovalsFilterSuggestions,
  DenyOrderSetsModal,
  OrderSetApprovalTable,
  OrderSetVariantSummaryTable,
  useApprovalActions,
  useDefaultApprovalStatus,
} from "@features/appovals";
import Filters, { useFilterParams } from "@features/filters";
import { RequestReportButton, useReport } from "@features/reports";
import { setOrderSetSelection } from "@redux/slices/orders/orderReviewSlice";
import { useApiResourcePaginated, useMutateError } from "@services/api";
import DocTitle from "@utility/DocTitle";
import useDeepCompareEffect from "@utils/useDeepCompareEffect";

const allStatuses = [
  "submitted",
  "approval-review",
  "approved",
  "canceled",
  "inactive",
  "partial-approval-error",
  "missed-payment",
];

const useParams = (otherFilters?: Record<string, any>) => {
  const [filters] = useFilterParams();
  const { currentChannelId } = useSelector((state: any) => state.currentUser);
  return {
    filter: {
      channelIds: currentChannelId && [currentChannelId],
      searchTerm: filters.q,
      territoryIds: filters.territories,
      programIds: filters.programs,
      userIds: filters.users,
      groupIds: filters.groups && Object.values(filters.groups).flat(),
      budgetIds: filters.budgets,
      types: filters.orderType && [filters.orderType],
      status:
        !filters.orderSetStatus || filters.orderSetStatus === "all"
          ? allStatuses
          : [filters.orderSetStatus],
      submittedFromDate: filters.fromDate,
      submittedToDate: filters.toDate,
      stateIds: filters.states,
      orderWindowIds: filters.orderWindows,
      ...otherFilters,
    },
    sort: filters.sort,
  };
};

const ApprovalsByVariant = () => {
  const params = useParams();

  useReport("order-set-variant-summary-approval", [
    "order-set-variant-summaries",
    params,
  ]);

  const { data, ...tableProps } = useApiResourcePaginated(
    "order-set-variant-summaries",
    {
      params,
      keepPreviousData: true,
    }
  );

  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <OrderSetVariantSummaryTable
        rows={data ?? []}
        query={params.filter.searchTerm}
        {...tableProps}
      />
    </OpaqueCard>
  );
};

const ApprovalsByOrder = () => {
  const params = useParams();
  useReport("order-set-approval", ["order-sets", params]);
  const { data, ...tableProps } = useApiResourcePaginated("order-sets", {
    params,
    keepPreviousData: true,
  });

  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <OrderSetApprovalTable
        rows={data ?? []}
        query={params.filter.searchTerm}
        {...tableProps}
      />
    </OpaqueCard>
  );
};

const Approvals = () => {
  const dispatch = useDispatch();
  const defaultApprovalStatus = useDefaultApprovalStatus();
  const { approveOrderSets, denyOrderSets } = useApprovalActions();
  const setMutateError = useMutateError();
  const orgUsesBudgets = useSelector(
    (state: any) => state.currentUser.organization.budgetLocation !== null
  );

  const [denyModalOpen, setDenyModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { selectedOrderSetIds } = useSelector(
    (state: any) => state.orderReview
  );
  const [filters] = useFilterParams();
  const reportName =
    filters.orderGroupBy === "item"
      ? "order-set-variant-summary-approval"
      : filters.orderGroupBy === "order"
      ? "order-set-approval"
      : null;

  const handleApprove = async () => {
    setLoading(true);
    await approveOrderSets(selectedOrderSetIds).catch(setMutateError);
    dispatch(setOrderSetSelection([]));
    setLoading(false);
  };

  const handleDeny = async (reason: string) => {
    setLoading(true);
    await denyOrderSets(selectedOrderSetIds, reason).catch(setMutateError);
    dispatch(setOrderSetSelection([]));
    setLoading(false);
  };

  useDeepCompareEffect(() => {
    dispatch(setOrderSetSelection([]));
  }, [filters]);

  const defaultFilters = {
    orderGroupBy: "order",
    orderSetStatus: defaultApprovalStatus,
    sort: "submitted-at",
  };

  return (
    <>
      <DocTitle title={"Approvals"} />
      {denyModalOpen && (
        <DenyOrderSetsModal
          handleClose={() => setDenyModalOpen(false)}
          handleDeny={handleDeny}
        />
      )}
      <FixedHeightScrollLastChild>
        <div tw="flex flex-wrap justify-between items-center">
          <h2 tw="text-2xl text-neutral-600 font-bold mb-2">Approvals</h2>
          <div tw="flex gap-3">
            {filters.orderGroupBy === "order" && (
              <>
                <StyledButton
                  cta
                  disabled={selectedOrderSetIds.length === 0}
                  onClick={handleApprove}
                  loading={loading}
                >
                  Approve
                </StyledButton>
                <StyledButton
                  outlined
                  disabled={loading || selectedOrderSetIds.length === 0}
                  onClick={() => setDenyModalOpen(true)}
                >
                  Deny
                </StyledButton>
              </>
            )}
            {reportName && <RequestReportButton reportName={reportName} />}
          </div>
        </div>
        <ApprovalsFilterSuggestions />
        <Filters
          searchTitle="Search Orders"
          slots={[
            "orderGroupBy",
            "orderSetStatus",
            ...(orgUsesBudgets ? ["budgets"] : []),
            "dateRange",
            "orderType",
            "states",
            "groups",
            "territories",
            "programs",
            "orderWindows",
            "users",
          ]}
          slotProps={{
            dateRange: {
              title: "Order was submitted",
              chipTextPrefix: "Submitted ",
            },
          }}
          defaultValues={defaultFilters}
        />
        {filters.orderGroupBy === "item" && <ApprovalsByVariant />}
        {filters.orderGroupBy === "order" && <ApprovalsByOrder />}
      </FixedHeightScrollLastChild>
    </>
  );
};

export default Approvals;
