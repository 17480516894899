/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";
import ControlledResourceFilterAutocomplete from "./ControlledResourceFilterAutocomplete";
import useSelectedResources from "./useSelectedResources";

const UserPopper = () => {
  const { control } = useFilterContext();
  return (
    <ControlledResourceFilterAutocomplete
      name="users"
      resource="users"
      resourceFilterName="nameOrEmail"
      requestParams={{ sort: "-name" }}
      control={control}
      inputPlaceholder="Filter Users"
    />
  );
};

const UserFilterChip = (props) => {
  const { filterValues } = useFilterContext();
  const selectedUsers = filterValues.users ?? [];
  const { data: users, isLoading } = useSelectedResources(
    "users",
    selectedUsers
  );

  return (
    <Chip {...props}>
      <span tw="text-neutral-600">User is&nbsp;</span>
      <span
        css={[
          tw`font-medium transition-opacity text-neutral-800`,
          isLoading && tw`opacity-75`,
        ]}
      >
        {[...users]
          .splice(0, 2)
          .map((user) => user.name)
          .join(", ")}
        {selectedUsers.length > 2 && (
          <span>, + {selectedUsers.length - 2}</span>
        )}
      </span>
    </Chip>
  );
};

const UserFilter = {
  Chip: UserFilterChip,
  Popper: UserPopper,
};

export default UserFilter;
